/* General Styles to Remove Horizontal Scrollbar */
body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal overflow */
    width: 100%;
    box-sizing: border-box;
}

h1, h2, h3, h4 {
    margin: 0;
}

/* Navbar Styles */
.navbar {
    position: fixed;
    top: 0;
    background-color: #ffffff; /* White background */
    color: #1B41BA;
    padding: 0.5rem 2rem; /* Reduced padding to decrease height */
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    width: 100%;
    box-sizing: border-box; /* Ensures padding doesn’t increase width */
}

/* Logo Styling */
.logo img {
    height: 75px; /* Adjusted to fit within reduced navbar height */
    width: auto;
    margin-left: 15%;
}

/* Center Nav Links */
.nav-links {
    list-style: none;
    display: flex;
    gap: 1.5rem;
    margin: 0 auto; /* Center links within navbar */
    padding: 0;
}

.nav-links a {
    color: #1B41BA; /* Dark color to match logo */
    text-decoration: none;
    font-weight: bolder;
    font-size: 1rem;
}

.nav-links a:hover {
    color: #7f6a0e; /* Darker color for hover effect */
}

/* Home Section Styles */
.home {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./images/Picture1.jpg');
    background-size: cover; /* Ensures image covers the full section */
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 100vh; /* Minimum viewport height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Aligns content to the left */
    width: 100vw; /* Ensures section covers full viewport width */
    overflow: hidden; /* Hides any overflow */
    box-sizing: border-box; /* Prevents padding from causing overflow */
}

/* Adjust all elements to have consistent left margin */
.home h1, .home p, .home button {
    margin-left: 10%; /* Adds left margin specifically for the button, heading, and paragraph */
}

.home h1 {
    font-size: 2.5rem;
    font-weight: bolder;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Adds shadow for better visibility */
    text-align: left;
}

.home p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    font-weight: bolder;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* Adds shadow for better visibility */
    text-align: left;
}

.home button {
    background-color: #1B41BA;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    align-self: flex-start;
}

.home button:hover {
    background-color: #7f6a0e;
}

/* About Section Styles */
.about {
    background-color: #f4f4f4;
    text-align: left;
    padding: 50px 10%; /* Added padding for more left spacing */
    min-height: 100vh; /* Full viewport height */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
}

.about-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    max-width: 1200px;
    width: 100%;
}

.about-text {
    flex: 1;
    max-width: 600px;
    padding-right: 20px; /* Adds more space on the right for better alignment */
}

.about-image img {
    width: 100%;
    max-width: 500px; /* Increased width for larger image display */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Services Section Styles */
.services {
    padding: 60px 10%;
    background-color: #4b766a;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
}

.services-content {
    max-width: 1000px;
    margin: 0 auto;
}

.services-text h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
    color: #f8f8f8;
}

.services-text h3 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
    color: #e0e0e0;
}

.services-text p {
    text-align: left;
    font-size: 1rem;
    margin-bottom: 30px;
    color: #dcdcdc;
    font-weight: bold;
}

.services h5 {
    color: #C4BAA1;
    font-weight: bolder;
}

/* 3x3 Grid Layout for Service Cards */
.service-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-items: center;
    margin-top: 20px;
}

.service-card {
    background-color: transparent;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    border-radius: 8px;
    text-align: left;
    margin: 15px;
}

.service-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
}

.service-card img:hover {
    transform: scale(1.05);
}

.service-card h4 {
    margin-top: 15px;
    font-size: 1.2rem; /* Slightly increased font size */
    color: #ffffff;
    font-weight: 900; /* Make h4 much bolder */
}

.service-card p {
    font-size: 0.9rem;
    color: #ffffff;
    font-weight: bold;
}

/* Vision Section Styles */
.vision {
    height: 100vh; /* Full viewport height */
    display: flex;
    align-items: center;
    padding-left: 10%; /* Aligns content more towards the left */
    background-color: #4b766a;
    color: #ffffff;
    text-align: left;
}

.vision-content {
    width: 60%; /* Occupies 60% of the screen width */
    max-width: 800px; /* Controls the max width of the content */
}

.vision-content p {
    font-size: 1.6rem; /* Larger font size for readability */
    line-height: 1.8; /* Increased line height for better readability */
    margin: 0; /* Remove margin from top and bottom */
    font-weight: bolder;
}

/* Contact Section Styles */
.contact {
    padding: 60px 10%;
    background-color: #ffffff;
    color: #333;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

/* 1. Top Left: Heading and Intro */
.contact-heading {
    max-width: 800px;
    margin-bottom: 20px;
}

.contact-heading h2 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin: 0; /* Remove any extra margin */
}

.contact-heading p {
    font-size: 1rem;
    color: #666;
    margin: 0; /* Remove any extra margin */
    line-height: 1.5; /* Adjust line height if needed */
}

/* 2. Middle Row: Three Child Divs */
.contact-values {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.contact-value {
    flex: 1;
    text-align: center;
    text-align: left;
}

.contact-value h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.contact-value p {
    font-size: 0.95rem;
    color: #666;
}

/* 3. Bottom Row: Contact Form and Image */
.contact-bottom {
    display: flex;
    gap: 20px;
    align-items: center; /* Center-aligns items vertically */
}

.contact-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-form label {
    font-weight: bold;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.contact-form button {
    background-color: #1B41BA;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.contact-form button:hover {
    background-color: #7f6a0e;
}

.contact-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.contact-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: to add rounded corners */
}

/* Footer Styles */
.footer {
    background-color: #4b766a;
    color: white;
    padding: 2rem 1rem;
}

.footer-content {
    display: flex;
    justify-content: space-between; /* Align sections to the left and right edges */
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 1rem;
}

.footer-section {
    text-align: left;
}

.footer-section h4 {
    font-size: 1.1rem;
    color: #ffffff;
    margin-bottom: 0.5rem;
    font-weight: bolder;
}

.footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-section ul li {
    margin-bottom: 0.5rem;
}

.footer-section ul li a,
.footer-section a {
    color: white;
    text-decoration: none;
}

.footer-section a:hover {
    color: #7f6a0e;
}

.footer-section address {
    font-style: normal;
    line-height: 1.5;
    margin-bottom: 1rem;
}

.footer-section:nth-child(1) {
    align-self: flex-start;
}

.footer-section:nth-child(2) {
    align-self: flex-end;
}

.footer-content > div {
    flex: 1;
    min-width: 200px;
}

.footer-content > div:nth-child(2) {
    text-align: right;
}

.footer-content > div:nth-child(1) {
    text-align: left;
}

.footer-copyright {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #ccc;
    text-align: center;
}
